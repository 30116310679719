import '../App.css';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// 各取引のデータ表示のタブを作成
function TransactionTabs(info) {
  // 表示中のタブを管理
  const [value, setValue] = React.useState('1');
  // 表示中のタブを制御するhandle
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* リストを切り替えるタブ */}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {/* 各タブの名前を設定 */}
            <Tab label="借りているもの一覧" value="1" />
            <Tab label="貸しているもの一覧" value="2" />
            <Tab label="全件" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {/* 選択されているユーザー情報をもとに借りているリストを表示 */}
          <BorrowedCards
            selectedUser={info.selectedUser}
            userList={info.userList} />
        </TabPanel>
        <TabPanel value="2">
          {/* 選択されているユーザー情報をもとに貸しているリストを表示 */}
          <LentCards
            selectedUser={info.selectedUser}
            userList={info.userList} />
        </TabPanel>
        <TabPanel value="3">
          {/* 全件のリストを表示 */}
          <AllCards userList={info.userList} />
        </TabPanel>
      </TabContext>
    </div>
  )
}

// 借りているものの一覧を作成
function BorrowedCards(info) {
  // APIのURL
  let url = 'https://us-money.bluejo-hn.com/api/getBorrowedTransaction?borrowedId=' + info.selectedUser;

  // データ取得処理
  const {
    data: transactions,
    isLoading: isBorrowedLoading,
  } = useQuery({ queryKey: ['borrowedTransactions', url], queryFn: () => fetchData(url) });

  return (
    <div>{!isBorrowedLoading ? (
      // ロードが終わっている場合
      transactions.length > 0 ? (
        // transactionsのリストが空でなければカードを生成
        transactions.map(transaction => (
          <Card key={transaction.id} className="transaction-card">
            {/* 登録日 */}
            <Box className="gray-text">{changeDateFormat(transaction.regist_date)}</Box>
            <Stack direction="row" spacing={10} alignItems="flex-end">
              <Box>
                {/* ユーザー名 */}
                <Box>
                  {info.userList.find(object => object.user_id === transaction.lent_id).name}
                </Box>
                {/* 金額 */}
                <Box>{transaction.amount}円</Box>
              </Box>
              {/* 返却ボタン */}
              <Box><ReturnButton id={transaction.id} /></Box>
            </Stack>
            {/* 詳細 */}
            <Box className="detail">{transaction.detail}</Box>
          </Card>
        ))
      ) : (
        // transactionsが空の場合
        <p>データが存在しません</p>
      )
    ) : (
      // ロード中の場合
      <p>ロード中</p>
    )}</div>
  )
}

// 貸しているものの一覧を作成
function LentCards(info) {
  // APIのURL
  let url = 'https://us-money.bluejo-hn.com/api/getLentTransaction?lentId=' + info.selectedUser;

  // データ取得処理
  const {
    data: transactions,
    isLoading: isLentLoading,
  } = useQuery({ queryKey: ['lentTransactions', url], queryFn: () => fetchData(url) });

  return (
    <div>{!isLentLoading ? (
      // ロードが終わっている場合
      transactions.length > 0 ? (
        // transactionsのリストが空でなければカードを生成
        transactions.map(transaction => (
          <Card key={transaction.id} className="transaction-card">
            {/* 登録日 */}
            <Box className="gray-text">{changeDateFormat(transaction.regist_date)}</Box>
            <Stack direction="row" spacing={10} alignItems="flex-end">
              <Box>
                {/* ユーザー名 */}
                <Box>
                  {info.userList.find(object => object.user_id === transaction.borrowed_id).name}
                </Box>
                {/* 金額 */}
                <Box>{transaction.amount}円</Box>
              </Box>
              {/* 返却ボタン */}
              <Box><ReturnButton id={transaction.id} /></Box>
            </Stack>
            {/* 詳細 */}
            <Box className="detail">{transaction.detail}</Box>
          </Card>
        ))
      ) : (
        // transactionsが空の場合
        <p>データが存在しません</p>
      )
    ) : (
      // ロード中の場合
      <p>ロード中</p>
    )}</div>
  )
}

// 全件一覧を作成
function AllCards(info) {
  // APIのURL
  let url = 'https://us-money.bluejo-hn.com/api/getNotReturnTransaction';

  // データ取得処理
  const {
    data: transactions,
    isLoading: isAllLoading,
  } = useQuery({ queryKey: ['allTransactions', url], queryFn: () => fetchData(url) });

  return (
    <div>{!isAllLoading ? (
      // ロードが終わっている場合
      transactions.length > 0 ? (
        transactions.map(transaction => (
          // transactionsのリストが空でなければカードを生成
          <Card key={transaction.id} className="transaction-card">
            {/* 登録日 */}
            <Box className="gray-text">{changeDateFormat(transaction.regist_date)}</Box>
            <Stack direction="row" spacing={10} alignItems="flex-end">
              <Box>
                {/* 貸しているユーザー名 */}
                <Box>
                  貸してる人：{info.userList.find(object => object.user_id === transaction.lent_id).name}
                </Box>
                {/* 借りているユーザー名 */}
                <Box>
                  借りている人：{info.userList.find(object => object.user_id === transaction.borrowed_id).name}
                </Box>
                {/* 金額 */}
                <Box>{transaction.amount}円</Box>
              </Box>
              {/* 返却ボタン */}
              <Box><ReturnButton id={transaction.id} /></Box>
            </Stack>
            {/* 詳細 */}
            <Box className="detail">{transaction.detail}</Box>
          </Card>
        ))
      ) : (
        // transactionsが空の場合
        <p>データが存在しません</p>
      )
    ) : (
      // ロード中の場合
      <p>ロード中</p>
    )}</div>
  )
}

// URLからデータを取得
const fetchData = async (url) => {
  const res = await fetch(url).then(resJson => resJson.json());
  return res.message;
}

// 返却ボタン
function ReturnButton(info) {
  // APIのURL
  let url = 'https://us-money.bluejo-hn.com/api/checkReturnFlg?id=' + info.id;

  // sleep設定
  const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (url) => fetchData(url),
    onSuccess: async () => {
      // データを即再取得すると更新が反映されていないことがあるので0.1s待つ
      await _sleep(100);
      // 返却後、DBからデータを再取得
      queryClient.invalidateQueries({ queryKey: ['borrowedAmount'] });
      queryClient.invalidateQueries({ queryKey: ['lentAmount'] });
      queryClient.invalidateQueries({ queryKey: ['borrowedTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['lentTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['allTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['borrowedAmountFrom'] });
    },
  });

  return (
    <Button variant="contained"
      onClick={(ev) => { mutation.mutate(url); ev.preventDefault(); }}>返却</Button>
  )
}

const changeDateFormat = (str) => {
  const date = new Date(str);
  return date.toLocaleString();
}

export default TransactionTabs;