import '../App.css';
import { useState } from 'react';
import RegistDialog from './Dialog';
import TransactionTabs from './TransactionList';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQuery } from '@tanstack/react-query';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useQueryClient } from '@tanstack/react-query';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';

function Menu() {
  // ユーザー一覧を取得
  let url = 'https://us-money.bluejo-hn.com/api/getUsers';

  // ユーザー一覧のデータ取得処理
  const {
    data: userData,
    isLoading: isUserLoading
  } = useQuery({ queryKey: ['users', url], queryFn: () => fetchData(url) });

  // 選択したユーザーを格納する
  const [selectedUser, setSelectedUser] = useState();

  // 選択されたユーザーの変更時に呼ばれるhandle
  const handleValueChange = (newValue) => {
    setSelectedUser(newValue);
  };

  // ユーザーのセレクトに渡す値
  // ユーザー選択時のhandle、ユーザセレクトに渡すリスト、選択されたユーザーIDを格納
  const infoForUserSelect = {
    userHandle: handleValueChange,
    userList: userData,
    isUserLoading: isUserLoading
  }

  // ダイアログに渡す値
  // 選択されたユーザーID、ユーザーリスト
  const infoUsers = { selectedUser: selectedUser, userList: userData }

  const navigate = useNavigate();

  // ログアウト処理
  const handleLogout = () => {
    auth.signOut();
    navigate('/login', { state: { message: '成功', type: 'success' } })
  };

  return (
    <Box>
      <Box id="menu">
        {/* ユーザー選択のセレクトボックス */}
        <Stack direction="row" spacing={4}
          useFlexGap flexWrap="wrap">
          <Box>
            <UserSelect userHandle={infoForUserSelect.userHandle}
              isUserLoading={isUserLoading}
              userList={infoForUserSelect.userList} />
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleLogout}>ログアウト</Button>
          </Box>
        </Stack>
        <Stack direction="row" spacing={4}
          useFlexGap flexWrap="wrap">
          {/* 借りている金額を表示 */}
          <Box><p>借りている金額：</p><BorrowedAmount id={selectedUser} /></Box>
          {/* 貸している金額を表示 */}
          <Box><p>貸している金額：</p><LentAmount id={selectedUser} /></Box>
        </Stack>
        <Box className="accordion-width box-margin">
          {/* 各メンバーから借りている総額一覧のアコーディオン */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              {/* アコーディオンのタイトル */}
              <Typography>各メンバーから借りている額</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* 一覧を表示 */}
              <BorrowedAmountWithUser selectedUser={infoUsers.selectedUser}
                isUserLoading={isUserLoading}
                userList={infoUsers.userList} />
            </AccordionDetails>
          </Accordion>
          {/* 各メンバーへ貸している総額一覧のアコーディオン */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              {/* アコーディオンのタイトル */}
              <Typography>各メンバーへ貸している額</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* 一覧を表示 */}
              <LentAmountWithUser selectedUser={infoUsers.selectedUser}
                isUserLoading={isUserLoading}
                userList={infoUsers.userList} />
            </AccordionDetails>
          </Accordion>
          {/* 各メンバーとの貸し借り差額一覧のアコーディオン */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              {/* アコーディオンのタイトル */}
              <Typography>各メンバーとの貸し借り差額</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* 一覧を表示 */}
              <p>貸している額 - 借りている額</p>
              <DiffAmountWithUser selectedUser={infoUsers.selectedUser}
                isUserLoading={isUserLoading}
                userList={infoUsers.userList} />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          {selectedUser > 0 ? (
            // 登録ダイアログの表示処理
            <RegistDialog selectedUser={infoUsers.selectedUser}
              userList={infoUsers.userList} />
          ) : (
            <Stack direction="row" spacing={1}
              useFlexGap flexWrap="wrap">
              <Button variant="outlined" disabled>新規追加</Button>
              <p className="gray-text">ユーザーを選択してください</p>
            </Stack>
          )}
        </Box>
      </Box>
      <Box>
        {/* 各取引のデータ表示 */}
        <TransactionTabs selectedUser={infoUsers.selectedUser}
          userList={infoUsers.userList} />
      </Box>
    </Box>
  )
}


// URLからデータを取得
const fetchData = async (url) => {
  // URLからデータを取得
  const res = await fetch(url).then(resJson => resJson.json());
  // 取得したデータを返却
  return res.message;
}


// ユーザーIDの一覧のセレクトボックスを作成
function UserSelect(info) {
  const queryClient = useQueryClient();
  // 選択したユーザーIDを保存
  const handleChange = (event) => {
    info.userHandle(event.target.value);
    queryClient.invalidateQueries({ queryKey: ['borrowedAmountFrom'] });
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 150 }}>
        <InputLabel id="user_select_InputLabel">ユーザー</InputLabel>
        <Select
          labelId="user_select_label"
          id="user_select"
          label="ユーザー"
          onChange={handleChange}>
          {!info.isUserLoading ? (
            // userListに中身があれば、セレクトボックスの中身を作成
            info.userList.map(user => (<MenuItem value={user.user_id} key={user.user_id}>{user.name}</MenuItem>))
          ) : (
            // userListに中身がなければ読み込まれるまでロード中と表示
            <p>Loading...</p>
          )}
        </Select>
      </FormControl>
    </div>
  );
};


// 選択したユーザーの借りている額を表示
function BorrowedAmount(info) {
  // APIのURL
  let url = 'https://us-money.bluejo-hn.com/api/getUserBorrowed?borrowedId=' + info.id;

  // データ取得処理
  const {
    data: amount,
    isLoading: isBorrowedAmountLoading
  } = useQuery({ queryKey: ['borrowedAmount', url], queryFn: () => fetchData(url) });

  return (
    <div>
      <p className="left-margin">{amount}円</p>
    </div>
  );
};


// 選択したユーザーの貸している額を表示
function LentAmount(info) {
  // APIのURL
  let url = 'https://us-money.bluejo-hn.com/api/getUserLent?lentId=' + info.id

  // データ取得処理
  const {
    data: amount,
    isLoading: isLentAmountLoading
  } = useQuery({ queryKey: ['lentAmount', url], queryFn: () => fetchData(url) });

  return (
    <div>
      <p className="left-margin">{amount}円</p>
    </div>
  );
};


// 各メンバーから借りている総額
function BorrowedAmountWithUser(info) {

  return (
    <Box className="box-width">{!info.isUserLoading ? (
      // ロードが終わっている場合
      info.userList.length > 0 ? (
        // ユーザー一覧の配列が空出なければ、借りている総額を取得
        info.userList.map(user => (
          // メンバーごとに配列をループ
          user.user_id != info.selectedUser ? (
            // 自分自身の場合はスキップ
            <Stack direction="row" spacing={1} justifyContent="space-between" key={"b" + user.user_id} >
              {/* 借りているユーザー名を表示 */}
              <Box>{user.name}：</Box>
              <Box>
                {/* 借りている額を表示 */}
                <GetBorrowedAmountWithUser
                  selectedUser={info.selectedUser}
                  user={user} />円
              </Box>
            </Stack>
          ) : (<Box></Box>)
        ))
        // 配列が空の場合
      ) : (<Box>データが存在しません</Box>)
      // ロード中の場合
    ) : (<Box>ロード中</Box>)
    }</Box>
  )
}

// 各メンバーから借りている金額の取得処理
function GetBorrowedAmountWithUser(info) {
  // ユーザー名からAPIのURLを作成
  let url = 'https://us-money.bluejo-hn.com/api/getAmountFromAToB?lentId='
    + info.user.user_id + '&borrowedId=' + info.selectedUser;

  // データの取得処理
  const {
    data: amount,
    isLoading: isLoading
  } = useQuery({
    queryKey: ['borrowedAmountFrom', url],
    queryFn: () => fetchData(url)
  });

  return amount;
}


// 各メンバーへ貸している総額
function LentAmountWithUser(info) {

  return (
    <Box className="box-width">{!info.isUserLoading ? (
      // ロードが終わっている場合
      info.userList.length > 0 ? (
        // ユーザー一覧の配列が空出なければ、借りている総額を取得
        info.userList.map(user => (
          // メンバーごとに配列をループ
          user.user_id != info.selectedUser ? (
            // 自分自身の場合はスキップ
            <Stack direction="row" spacing={1} justifyContent="space-between" key={"l" + user.user_id}>
              {/* 借りているユーザー名を表示 */}
              <Box>{user.name}：</Box>
              <Box>
                {/* 借りている額を表示 */}
                <GetLentAmountWithUser
                  selectedUser={info.selectedUser}
                  user={user} />円
              </Box>
            </Stack>
          ) : (<Box></Box>)
        ))
        // 配列が空の場合
      ) : (<Box>データが存在しません</Box>)
      // ロード中の場合
    ) : (<Box>ロード中</Box>)
    }</Box>
  )
}


// 各メンバーへ貸している金額の取得処理
function GetLentAmountWithUser(info) {
  // ユーザー名からAPIのURLを作成
  let url = 'https://us-money.bluejo-hn.com/api/getAmountFromAToB?lentId='
    + info.selectedUser + '&borrowedId=' + info.user.user_id

  // データの取得処理
  const {
    data: amount,
    isLoading: isLoading
  } = useQuery({
    queryKey: ['LentAmountFrom', url],
    queryFn: () => fetchData(url)
  });

  return amount;
}


// 各メンバーとの貸し借り差額
function DiffAmountWithUser(info) {

  return (
    <Box className="box-width">{!info.isUserLoading ? (
      // ロードが終わっている場合
      info.userList.length > 0 ? (
        // ユーザー一覧の配列が空出なければ、借りている総額を取得
        info.userList.map(user => (
          // メンバーごとに配列をループ
          user.user_id != info.selectedUser ? (
            // 自分自身の場合はスキップ
            <Stack direction="row" spacing={1} justifyContent="space-between" key={"l" + user.user_id}>
              {/* 借りているユーザー名を表示 */}
              <Box>{user.name}：</Box>
              <Box>
                {/* 借りている額を表示 */}
                <DiffAmount
                  selectedUser={info.selectedUser}
                  user={user} />円
              </Box>
            </Stack>
          ) : (<Box></Box>)
        ))
        // 配列が空の場合
      ) : (<Box>データが存在しません</Box>)
      // ロード中の場合
    ) : (<Box>ロード中</Box>)
    }</Box>
  )
}


// 貸し借り差額計算
function DiffAmount(info) {
  // 貸してる金額取得
  let lentAmount = GetLentAmountWithUser({ selectedUser: info.selectedUser, user: info.user });
  // 借りてる金額取得
  let borrowedAmount = GetBorrowedAmountWithUser({ selectedUser: info.selectedUser, user: info.user });

  let diff = lentAmount - borrowedAmount;

  return diff;
}

export default Menu;