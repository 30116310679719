import '../App.css';
import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// 新規追加ダイアログ
function RegistDialog(info) {
  // ダイアログの開閉を管理
  const [open, setOpen] = useState(false);
  // 貸し借りのラジオボタンの値を格納
  const [isBorrowed, setIsBorrowed] = useState(false);
  // 金額を格納
  const [amount, setAmount] = useState(0);
  // 日付を格納
  const [date, setDate] = useState(formatDate(new Date()));
  // 詳細の文字列を格納
  const [detail, setDetail] = useState('');
  // 選択されたユーザーのIDを格納
  const [checkedUsers, setCheckedUsers] = useState([]);

  // チェックボックスからユーザーを取得するためのHandle
  const handleUsers = (newValue) => {
    setCheckedUsers(newValue);
  };

  // 貸し借りのラジオボタンの値を取得するためのHandle
  const handleIsBorrowed = (newValue) => {
    setIsBorrowed(newValue);
  }

  // ダイアログを開く
  const handleClickOpen = () => {
    setOpen(true);
  };

  // ダイアログを閉じる
  const handleClose = () => {
    setOpen(false);
  };

  // ユーザー選択のチェックボックスに渡す変数
  const infoUsers = {
    userHandle: handleUsers,
    userList: info.userList,
    checkedUsers: checkedUsers
  };

  // DBへ送る変数
  const infoForDb = {
    user: info.selectedUser,
    selectedUsers: checkedUsers,
    amount: amount,
    registDate: date,
    detail: detail,
    isBorrowed: isBorrowed
  }

  // テキストフィールドの内容を初期化
  const initInput = () => {
    setIsBorrowed(false);
    setAmount(0);
    setDate(formatDate(new Date()));
    setDetail('');
    setCheckedUsers([]);
  }

  // sleep設定
  const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // DBへのインサート処理
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (infoForDb) => insertDb(infoForDb),
    onSuccess: async () => {
      // データを即再取得すると更新が反映されていないことがあるので0.1s待つ
      await _sleep(100);
      // DBへ登録後、DBからデータを再取得
      queryClient.invalidateQueries({ queryKey: ['borrowedAmount'] });
      queryClient.invalidateQueries({ queryKey: ['lentAmount'] });
      queryClient.invalidateQueries({ queryKey: ['borrowedTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['lentTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['allTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['borrowedAmountFrom'] });
      // テキストフィールドの内容を初期化
      initInput();
    },
  });

  return (
    <Box className="black-background">
      <React.Fragment>
        {/* 新規追加ボタン */}
        <Button variant="outlined" onClick={handleClickOpen}>
          新規追加
        </Button>
        {/* ダイアログ全体の処理 */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (ev) => {
              // これを書かないと入力値に変更があるたびにAPIにデータが送信されてしまう
              ev.preventDefault();
              // データ送信処理
              mutation.mutate(infoForDb);
              // ダイアログをクローズ
              handleClose();
            },
          }}>

          <DialogTitle>貸し借り登録</DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <Stack direction="row" spacing={4}
              useFlexGap flexWrap="wrap"
              alignItems="flex-end">
              {/* 金額入力のテキストフィールド */}
              <Box className="box-margin">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="amount_text"
                  name="amount"
                  label="金額"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="standard" />
              </Box>
              {/* 日時設定のテキストフィールド */}
              <Box className="box-margin">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="date_text"
                  name="date"
                  type="datetime-local"
                  value={date}
                  // デフォルトとして現在時刻を設定
                  defaultValue={formatDate(new Date())}
                  onChange={(e) => setDate(e.target.value)}
                  variant="standard" />
              </Box>
            </Stack>
            {/* 詳細記載のテキストフィールド */}
            <Box className="box-margin">
              <TextField
                id="detail_text"
                label="内容"
                multiline
                fullWidth
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
                variant="standard" />
            </Box>
            {/* 貸し借りのラジオボタン */}
            <Box className="box-margin">
              <LentOrBorrowed handleIsBorrowed={handleIsBorrowed} />
            </Box>
            {/* メンバー選択のチェックボックス */}
            <MemberCheckbox userHandle={infoUsers.userHandle}
              userList={infoUsers.userList}
              checkedUsers={infoUsers.checkedUsers} />
          </DialogContent>
          {/* 追加ボタン */}
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">追加</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Box>
  );
}

// ダイアログ入力された情報をAPIで送信
function insertDb(infoForDb) {
  // URLを設定
  const url = 'https://us-money.bluejo-hn.com/api/registTransaction';
  // オプションを設定
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(infoForDb)
  };

  fetch(url, options)
}

// 貸し借り選択
function LentOrBorrowed(info) {
  // 貸し借りの選択をuseStateに格納
  const handleChange = (e) => {
    info.handleIsBorrowed(e.target.value ? 'borrowed' : 'lent');
  }

  return (
    <FormControl>
      <FormLabel id='lent_borrowed_radio_label'>貸し借り</FormLabel>
      <RadioGroup
        aria-labelledby='lent_borrowed_radio'
        defaultValue='lent'
        name="radio-buttons-group"
        onChange={handleChange}>
        <Stack direction="row" spacing={2} >
          <FormControlLabel value='lent' control={<Radio />} label="貸し" />
          <FormControlLabel value='borrowed' control={<Radio />} label="借り" />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}


// メンバー選択チェックボックス
function MemberCheckbox(info) {
  // チェックボックスで選択されたユーザーをuseStateに格納
  const userSelectedHandle = (value) => {
    info.userHandle(value);
  };

  // チェックボックスのチェック切り替え処理
  // チェックボックスの値からチェックされたユーザーを指定し配列に格納
  const handleChange = (e) => {
    if (info.checkedUsers.includes(Number(e.target.value))) {
      // すでに配列にチェックされたユーザーが格納されていた場合、配列からユーザーを削除
      userSelectedHandle(
        info.checkedUsers.filter((checkedValue) => checkedValue !== Number(e.target.value))
      );
    } else {
      // チェックされたユーザーが配列になければ、格納
      userSelectedHandle([...info.checkedUsers, Number(e.target.value)]);
    }

  };

  // 全部のチェックボックスをチェック
  const selectAll = (e) => {
    let bool = e.target.checked ? true : false

    if (bool) {
      // 「全員」チェックボックスがチェックされた場合、checkedValuesに値を全て入れる
      userSelectedHandle([
        info.userList[0].user_id,
        info.userList[1].user_id,
        info.userList[2].user_id,
        info.userList[3].user_id,
        info.userList[4].user_id
      ]);
    } else {
      // 「全員」チェックボックスがチェックされた場合、checkedValuesを空にする
      userSelectedHandle([]);
    }
  };

  return (
    <Box>
      <Box>
        {/* 「全員」のチェックボックス */}
        <FormControlLabel control={<Checkbox />}
          checked={
            // 全員分のチェックボックスがチェックされていればチェックする
            info.checkedUsers.includes(info.userList[0].user_id)
            && info.checkedUsers.includes(info.userList[1].user_id)
            && info.checkedUsers.includes(info.userList[2].user_id)
            && info.checkedUsers.includes(info.userList[3].user_id)
            && info.checkedUsers.includes(info.userList[4].user_id)
          }
          onChange={selectAll} label="全員" />
      </Box>
      {/* それぞれのメンバーのチェックボックス */}
      <FormGroup>
        <Stack className="left-margin" 
        direction="row" spacing={1} 
        useFlexGap flexWrap="wrap">
          {info.userList.length > 0 ? (
            // userListに中身があれば、チェックボックスを表示
            info.userList.map(user => (<FormControlLabel control={<Checkbox />}
              // ユーザーIDがcheckedUserにあればチェックする
              checked={info.checkedUsers.includes(user.user_id)}
              value={user.user_id}
              onChange={handleChange} label={user.name} />))
          ) : (
            // userListに中身がなければ読み込まれるまでロード中と表示
            <p>ロード中</p>
          )}
        </Stack>
      </FormGroup>
    </Box>
  )
}


// 日付をYYYY-MM-DDTHH:MMに変更
// このフォーマットでないとデフォルトに設定できないため、変換の必要がある
function formatDate (today) {
  // 現在時刻から年、月、日、時、分をそれぞれ取得
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const hour = String(today.getHours()).padStart(2, '0');
  const minutes = String(today.getMinutes()).padStart(2, '0');
  // 取得した各要素をフォーマットに従って再配置
  const formattedDate = `${year}-${month}-${day}T${hour}:${minutes}`;
  return formattedDate;
};

export default RegistDialog;