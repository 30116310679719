import '../App.css';
import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { auth } from '../Firebase';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate, Navigate } from 'react-router-dom';

function Login() {
  // メールアドレスを格納
  const [email, setEmail] = useState();
  // パスワードを格納
  const [password, setPassword] = useState();

  const navigate = useNavigate();

  // ログインボタン押下時の処理
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const { email, password } = event.target.elements;
    try {
      // Firebaseのログイン処理
      await signInWithEmailAndPassword(auth, email, password);
      // ログイン成功ならHomeに飛ばす
      navigate('/', { state: { message: '成功', type: 'success' } })
    } catch (error) {
      // ログイン失敗時の処理
      console.log(error);
    }
  };

  // パスワードの表示非表示管理
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box id="login-box" className="box-width">
      <Stack>
        <form component="form" onSubmit={handleSubmit}>
          <Box>
            <FormControl>
              <InputLabel htmlFor="standard-adornment-mail">Mail</InputLabel>
              <Input
                id="standard-adornment-weight"
                required
                variant="Mail"
                aria-describedby="standard-weight-helper-text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{
                  'aria-label': 'Mail',
                }}
              />
            </FormControl>
            <Box className="box-margin"></Box>
            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Box id="login-button">
            <FormControl>
              <Button type="submit" variant="outlined">
                ログイン
              </Button>
            </FormControl>
          </Box>
        </form>
      </Stack>
    </Box >
  )
}

export default Login;