import './App.css';
import Home from './ui/Home';
import Login from './ui/Login';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import pink from '@mui/material/colors/pink';
import cyan from '@mui/material/colors/cyan';
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchInterval: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false
    },
  },
});

const theme = createTheme({
  palette: {
    primary: pink,
    secondary: cyan,
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Box>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/login" element={<Login/>} />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </Box>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;