import { auth } from '../Firebase';
import Menu from './Menu';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

function Home() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  if (!user) {
    // ログインしていない場合ログイン画面に遷移させる
    return <Navigate to="/login" />;
  } else {
    // ログイン済みならMenuを表示
    return (
      <Menu />
    );
  }
};

export default Home;